<template>
  <div>
    <v-data-table v-if="!isLoading" :headers="headers" :items="rewardSuggestions">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" class="mr-3" v-on="on" @click="editReward(item)">mdi-pencil</v-icon>
          </template>
          Módosítás
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" v-on="on" @click="openDeleteConfirmator(item)">mdi-delete</v-icon>
          </template>
          Törlés
        </v-tooltip>
      </template>
    </v-data-table>

    <v-skeleton-loader type="table" v-else />
    <ConfirmDialog :confirm-handler="deleteConfirmHandler" />
    <RewardsDialog />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { DELETE_REWARD_SUGGESTION } from '@/store/types'

const ConfirmDialog = () => import('@/components/Global/ConfirmDialog.vue')
const RewardsDialog = () => import('@/components/RewardSuggestions/RewardSuggestionsDialog.vue')

export default {
  name: 'RewardSuggestionsTable',
  components: {
    ConfirmDialog,
    RewardsDialog
  },
  data() {
    return {
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Név',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Nyelv',
          align: 'start',
          value: 'language'
        },
        {
          text: 'Kategória',
          align: 'start',
          value: 'category'
        },
        {
          text: 'Kiemelt',
          align: 'start',
          value: 'highlighted'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      deleteConfirmHandler: {}
    }
  },
  computed: {
    ...mapState({
      status: state => state.rewardSuggestions.status,
      rewardSuggestions: state => state.rewardSuggestions.rewards
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  methods: {
    openDeleteConfirmator(reward) {
      this.deleteConfirmHandler = {
        handler: () => this.deleteReward(reward),
        title: `${reward.name} törlése`
      }
      this.$modal.show('confirm-dialog')
    },
    async deleteReward(reward) {
      await this.$store.dispatch(DELETE_REWARD_SUGGESTION, reward.id)
      this.$modal.hide('confirm-dialog')
    },
    editReward(reward) {
      this.$modal.show('reward-suggestion-dialog', {
        reward,
        isNewReward: false
      })
    }
  }
}
</script>
